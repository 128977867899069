import { render, staticRenderFns } from "./perfil_usuario.vue?vue&type=template&id=94b4aab8&scoped=true&"
import script from "./perfil_usuario.js?vue&type=script&lang=js&"
export * from "./perfil_usuario.js?vue&type=script&lang=js&"
import style0 from "./perfil_usuario.scss?vue&type=style&index=0&id=94b4aab8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94b4aab8",
  null
  
)

export default component.exports