class usuarioService {//servicios para login
  axios
  baseUrl

  constructor(axios, baseUrl) {
    this.axios = axios;
    this.baseUrl = `${baseUrl}`;
  }

  getUsuarioFaltante(id) {
    return this.axios.get(`${this.baseUrl}/usuario/faltante/${id}`)
  }

  confirmar_cambiar_password(body) {
    let url = `${this.baseUrl}/recuperacion/cambio`
    return this.axios.put(url.toString(), body);
  }

  confirmar_correo(body) {
    let url = `${this.baseUrl}/correo/verificar`
    return this.axios.post(url.toString(), body);
  }

  actualizar_tributarios(body) {
    let url = `${this.baseUrl}/usuario/datos_tributarios/actualizar`
    return this.axios.put(url.toString(), body);
  }

  getListaFormaPago() {
    let url = `${this.baseUrl}/usuario/forma_pago/listado`
    return this.axios.get(url.toString());
  }

  activarTarjeta(id) {
    let url = `${this.baseUrl}/usuario/forma_pago/activar/${id}`
    return this.axios.put(url.toString());
  }

  eliminarTarjeta(body) {
    let url = `${this.baseUrl}/usuario/forma_pago/eliminar`
    return this.axios.delete(url.toString(), { data: body })
  }

  insertarTarjeta(body) {
    let url = `${this.baseUrl}/usuario/forma_pago/insertar`
    return this.axios.post(url.toString(), body);
  }

  cambiarContrasena(body) {
    let url = `${this.baseUrl}/usuario/cambio/pass`
    return this.axios.put(url.toString(), body);
  }

  cancelarSuscripcion(body) {
    let url = `${this.baseUrl}/suscripcion/cancelar`
    return this.axios.post(url.toString(), body);
  }

  getListadoCorreo() {
    let url = `${this.baseUrl}/correo/listado`
    return this.axios.get(url.toString());
  }

  reenviarCorreo(body) {
    let url = `${this.baseUrl}/correo/reenviar`
    return this.axios.post(url.toString(), body);
  }

  recordatorio(id) {
    let url = `${this.baseUrl}/notificacion/recordatorio/${id}`
    return this.axios.get(url.toString());
  }

  postCambiarSuscripcion(body){
    let url = `${this.baseUrl}/suscripcion/cambio`
    return this.axios.post(url.toString(), body);
  }

  postRenovarSuscripion(body){
    let url = `${this.baseUrl}/suscripcion/nuevo`
    return this.axios.post(url.toString(), body);
  }

  getSuscripcionActual(){
    let url = `${this.baseUrl}/suscripcion/actual`
    return this.axios.get(url.toString());  
  }

  cambiar_correo_electronico(body){
    let url = `${this.baseUrl}/correo/actualizar`
    return this.axios.put(url.toString(), body);
  }

  verificar_correo(id){
    let url = `${this.baseUrl}/correo/verificar/admin/${id}`
    return this.axios.get(url.toString());  
  }

  cambiar_contrasena_admin(body){
    let url = `${this.baseUrl}/usuario/cambio/pass/admin`
    return this.axios.put(url.toString(), body);
  }

  getInfoUsuario(){
    let url = `${this.baseUrl}/usuario/informacion`
    return this.axios.get(url.toString());  
  }

  updateInfo(body){
    let url = `${this.baseUrl}/usuario/actualizar`
    return this.axios.put(url.toString(), body);
  }

  obtenerSuscripcionesUsuario(id){
    let url = `${this.baseUrl}/suscripcion/usuario/${id}`
    return this.axios.get(url.toString());  
  }

  obtenerDetallePresentaciones(id){
    let url = `${this.baseUrl}/suscripcion/detalle/${id}`
    return this.axios.get(url.toString());  
  }

  obtenerDetallePresentacionCompleta(usuario_id, anio){
    let url = `${this.baseUrl}/suscripcion/detalle_declaracion/${usuario_id}/${anio}`
    return this.axios.get(url);
  }
 
  getInfoBancaSat(){
    let url = `${this.baseUrl}/usuario/data_sat`
    return this.axios.get(url);
  }

  postPagoSuscripcionSale(body){
    let url = `${this.baseUrl}/pago/sale`
    return this.axios.post(url, body);
  }

  postPagoSuscripcionRebill(body){
    let url = `${this.baseUrl}/pago/rebill`;
    return this.axios.post(url, body);
  }

  postPagoVoid(body){
    let url = `${this.baseUrl}/pago/void`
    return this.axios.post(url, body);
  }

  getRolUsuario(id){
    let url = `${this.baseUrl}/permisos/usuario/${id}`
    return this.axios.get(url);
  }

  getRoles(){
    let url = `${this.baseUrl}/rol/listado`
    return this.axios.get(url);
  }

  postInsertarPermisos(body){
    let url = `${this.baseUrl}/permisos/insertar`
    return this.axios.post(url, body);
  }

  getTokenTarjeta(){
    let url = `${this.baseUrl}/usuario/token_tarjeta`
    return this.axios.get(url);
  }

  getDocumentoSuscripcion(suscripcion_id){
    let url = `${this.baseUrl}/suscripcion/documento/${suscripcion_id}`
    return this.axios.get(url);
    
  }
  
  getFacturasUsuario(mes, anio, usuario_id){
    let url = `${this.baseUrl}/ros/facturas/recibidas/${mes}/${anio}/${usuario_id}`
    return this.axios.get(url);
  }

  postFacturasIncluidas(body){
    let url = `${this.baseUrl}/ros/facturas/recibidas/inlcuido`
    return this.axios.post(url, body);
  }

  getTipoPerfilDeclaracion(id_actividad_economica){
    let url = `${this.baseUrl}/perfil_declaracion/buscar/${id_actividad_economica}`
    return this.axios.get(url);
  }

  updatePerfilDeclaracion(item_perfil){
    let url = `${this.baseUrl}/perfil_declaracion/perfil/cambio`
    return this.axios.put(url, item_perfil);
  }

  getPerfilDeclaracionUsuario(usuario_id){
    let url = `${this.baseUrl}/perfil_declaracion/usuario/${usuario_id}`
    return this.axios.get(url);
  }


}

export default usuarioService;