
import useVuelidate from '@vuelidate/core';


export function validMayuscula(name) {
  let validNamePattern = new RegExp("(?=.*?[A-ZÑ])");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validMinuscula(name) {
  let validNamePattern = new RegExp("(?=.*?[a-zñ])");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validDigito(name) {
  let validNamePattern = new RegExp("(?=.*?[0-9])");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validEspecial(name) {
  let validNamePattern = new RegExp("[#?!@$%&*-_+|°~]");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validLongitud(name) {
  let validNamePattern = new RegExp(".{8,}");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validVacio(name) {
  let validNamePattern = new RegExp(".{1,}");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function checkPassword(name) {
  let validNamePattern = this.newPass.pass_nueva;
  if (validNamePattern == name) {
    return true;
  }
  return false;
}
export default {

  setup() {
    return { v$: useVuelidate() }
  },

  name: "newPass",
  data() {
    return {
      dialogDocs: false,
      src: null,
      titulo: '',
      loading: false,
      departamentos: [],
      municipios: [],
      newPass: {
        confirmPassword: "",
        pass_nueva: "",
      },
    };
  },

  validations: {
    newPass: {
      pass_nueva: {
        mayuscula_validation: {
          $validator: validMayuscula,
          $message: 'Should be at least one uppercase'
        },
        minuscula_validation: {
          $validator: validMinuscula,
          $message: 'Should be at least one lowercase'
        },
        digito_validation: {
          $validator: validDigito,
          $message: 'Should be at least one digit'
        },
        especial_validation: {
          $validator: validEspecial,
          $message: 'Should be this characters (%$&#@-_)'
        },
        longitud_validation: {
          $validator: validLongitud,
          $message: 'Should be at least 8 characters long'
        },
        vacio_validation: {
          $validator: validVacio,
          $message: 'Password is required'
        },
      },
      confirmPassword: {
        vacio_validation: {
          $validator: validVacio,
          $message: 'Password is required'
        },
        confimar_validation: {
          $validator: checkPassword,
          $message: 'The password does not match'
        },
      },

    }

  },
  async created() {


  },
  methods: {
    clear() {
      this.newPass = {
        confirmPassword: "",
        password: "",
      }
    },
    async cambiarPass() {
      //console.log(this.v$.newPass.$touch());
      this.loading = true;
      // console.log(this.$route.params.encriptado);
      try {
        this.newPass.token = this.$route.params.encriptado;
        this.newPass.password = this.newPass.pass_nueva;
        let r = await this.$store.state.services.administradorService.confirm_reset_password(this.newPass);
        console.log(r);

        if (r.status == 200) {
          this.$toast.success("Your password has been changed")
          this.loading = false;
          this.$router.push("/")
        } else {
          this.$toast.error("Error not is possible change your password")
          this.loading = false;

        }
      } catch (error) {
        this.$toast.error("Your password has not been changed")
        this.loading = false;
        console.log(error);
      }

    },
  },


};