class loginService {//servicios para login
    axios
    baseUrl

    constructor(axios, baseUrl) {
        this.axios = axios;
        this.baseUrl = `${baseUrl}`;
    }

    getAll() {
        //console.log("todo ok")
        let self = this;
        //console.log(self.baseUrl + "/prueba")
        return self.axios.get(`${self.baseUrl}/prueba`)
    }

    postLogin(body) {//POST validación de credenciales
        let self = this;
        const url = `${self.baseUrl}/user/login`
        return self.axios.post(url.toString(), body);
    }

    postCompletar(body) {
        const url = `${this.baseUrl}/usuario/completar`
        return this.axios.put(url.toString(), body);
    }

    putCambiarContrasena(body){
        const url = `${this.baseUrl}/recuperacion`
        return this.axios.post(url.toString(),body);
    }

}

export default loginService;