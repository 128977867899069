<template>
  <md-content md-theme="selection">
    <router-view></router-view>
  </md-content>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    //console.log("url usada", process.env.VUE_APP_SERVICE_URL);
  },
};
</script>

<style lang="scss">
.text-gold {
  color: #f1a300;
}

@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme(
  "bottom-bar-gold",
  (
    primary: #f1a300,
  )
);

@include md-register-theme(
  "selection",
  (
    accent: #f1a300,
  )
);

@import "~vue-material/dist/theme/all"; // Apply the theme

@font-face {
  font-family: "Ubuntu-B";
  src: local("Ubuntu-B"), url("./assets/fonts/Ubuntu-B.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-L";
  src: local("Ubuntu-L"), url("./assets/fonts/Ubuntu-L.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-M";
  src: local("Ubuntu-M"), url("./assets/fonts/Ubuntu-M.ttf") format("truetype");
}
</style>