import { render, staticRenderFns } from "./recuperar_contraseña.vue?vue&type=template&id=1ecc9c0b&scoped=true&"
import script from "./recuperar_contraseña.js?vue&type=script&lang=js&"
export * from "./recuperar_contraseña.js?vue&type=script&lang=js&"
import style0 from "./recuperar_contraseña.scss?vue&type=style&index=0&id=1ecc9c0b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ecc9c0b",
  null
  
)

export default component.exports