

import credit_card from "../../shared/credit_card/credit_card.vue"
import $ from "jquery";


export default {
  props: ['mostrarDialogTarjeta'],
  components: { credit_card },

  watch: {
    mostrarDialogTarjeta() {
      this.dialog = this.mostrarDialogTarjeta
      this.volver();

    }
  },

  data: () => ({
    dialog: false,
    step_tajeta_nueva:1,
  }),

  created() {
  },

  methods: {

    crearTarjeta(card){
      this.step_tajeta_nueva = 1;
      this.$emit('crearTarjeta',card)
    },

    verifica_tarjeta() {
      if (sessionStorage.getItem("CARD_REQUEST") != null) {
        this.step_tajeta_nueva = 2;
        this.$toast.success("Su tarjeta es válida , puede proceder a realizar el pago", { timeout: 5000 })
      } else {
        console.log("no llenado el formulario");
        this.$toast.warning("No hemos verificado la tarjeta, presione el botón 'Verificar Tarjeta'");
      }
    },

    volver() {
      $(document).ready(function () {
        $.ajax({

          "type": "GET",

          "url": "https://bacgateway.merchantprocess.net/SecureComponent/UIComponent/CreditCard",

          "data": { "APIKey": $("#txtAPIKey").val(), "Token": $("#txtToken").val(), "Culture": "es" },

          "success": function (jsonResponse) {

            $("#creditcard-container").html(jsonResponse);

            $("#creditcard-container").slideDown(500);
          }
        });
      });
    },

   }
}