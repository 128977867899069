
import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from './App'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from 'vue-axios';


Vue.config.productionTip = false

/*
*Toast notifaciones
*/
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

/**
 * Tooltip
 */
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

/**
 * Veutify 
 */
import vuetify from './plugins/vuetify'

/**
 * v-mask 
 */
import VueMask from 'v-mask'


/**
 * vuetify-money
 */
 import "./plugins/vuetify-money";

/**
 * maz ui
 */
import 'maz-ui/lib/css/index.css';






Vue.use(VueMask)
Vue.use(Toast, { timeout: 2500, position: 'bottom-right' });
Vue.use(VueMaterial)
Vue.use(Tooltip);


new Vue({
  vuetify,
  router,
  store,
  axios,
  VueAxios,
  render: h => h(App)
}).$mount('#app')
