<template>
  <error text="PAGE NOT FOUND, PLEASE CHECK YOUR INTERNET CONECTION OR TRY AGAIN LOGIN" />
</template>

<script>
import error from "../../components/shared/error/error.vue";
export default {
  components: {
    error,
  },
  // name: "errorinfo",
};
</script>