class reportesService {//servicios para login
  axios
  baseUrl

  constructor(axios, baseUrl) {
    this.axios = axios;
    this.baseUrl = `${baseUrl}`;
  }

  getReportePendientePago(fecha,colaborador_id) {
    return this.axios.get(`${this.baseUrl}/reporte/pendiente_pago/${fecha}/${colaborador_id}`)
  }

  getReporteSeguimientoPago(fecha,colaborador_id) {
    return this.axios.get(`${this.baseUrl}/reporte/seguimiento/${fecha}/${colaborador_id}`)
  }

  obtenerPdfComprasVentas(usuario_id,mes,anio){
    return this.axios.get(`${this.baseUrl}/reporte/pdf/libro_compras_ventas/${usuario_id}/${mes}/${anio}`); 
  }

  obtenerCierreAnual(anio,usuario_id){
    let url = `${this.baseUrl}/cierre/anual/pdf/${usuario_id}/${anio}`
    return this.axios.get(url);
  }

  reporteMaestroMes(fecha){
    let url = `${this.baseUrl}/reporte/maestro/mes/${fecha}`
    return this.axios.get(url);
  }



}

export default reportesService;