<template>
  <error text="A OCURRIDO UN ERROR AL CONSULTAR LOS DATOS" />
</template>

<script>
import error from "../../components/shared/error/error.vue";
export default {
  components: {
    error,
  },
  // name: "423",
};
</script>