export default {
	data: () => ({
		loading: false,
		items: [],
		destinations: [],
		search: '',
		headers_origins: [
			{ text: 'Municipio', value: 'name', align: 'start', sortable: true },
			{ text: 'Departamento', value: 'departments.name', align: 'start', sortable: true },
			{ text: 'País', value: 'departments.country.name', align: 'start', sortable: true },

			{ text: 'Acciones', value: 'actions', sortable: false },
		],
		item_aux: {},
		isEdit: false,
		isDelete: false,
		dialog_edit: false,
		departments: [],
		valid: true,

	}),

	async created() {

		let r1 = await this.get_all_municipalities();
		let r2 = await this.get_all_department();

		await Promise.all([r1, r2]);
	},

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},

	methods: {

		close_dialog_edit() {
			try {
				if (!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.item_aux = {
					department_id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		modificar_item(item) {
			this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);
			this.isDelete = false;
			this.dialog_edit = true;
			this.isEdit = true;
		},

		exportarExcel() {
			let data = XLSX.utils.json_to_sheet(this.origins)
			const workbook = XLSX.utils.book_new()
			const filename = 'Municipioes'
			XLSX.utils.book_append_sheet(workbook, data, filename)
			XLSX.writeFile(workbook, `${filename}.xlsx`)
		},

		delete_item(item) {
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		crear_item() {
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},

		acept_edit() {
			if (this.isEdit) {
				console.log("editando", this.item_aux);
				this.update_municipio();
				this.close_dialog_edit();
			} else {
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.create_municipio();
				this.close_dialog_edit();
			}
		},

		acept_delete() {
			this.delete_municipality(this.item_aux);
			this.close_dialog_edit();
		},

		async delete_municipality(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.delete_municipality(item.id);

				if (r.status != 200) throw new Error(r);

				this.$toast.success("Municipio eliminado", { timeout: 2000 });
				this.get_all_municipalities();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar el Municipio", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_department() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_department();
				this.departments = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los Municipioes", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_municipalities() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_municipality();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los municipios", { timeout: 2000 });
				this.loading = false;
			}
		},

		async create_municipio() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.post_municipality(this.item_aux);
				this.$toast.success("Municipio creado", { timeout: 2000 });
				this.get_all_municipalities();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el Municipio", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_municipio() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.put_municipality(this.item_aux);
				this.$toast.success("Municipio actualizado", { timeout: 2000 });
				this.get_all_municipalities();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el Municipio", { timeout: 2000 });
				this.loading = false;
			}
		},

	}

}

