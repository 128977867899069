import * as XLSX from 'xlsx'
import CryptoJS from "crypto-js";

export default {
	data() {
		return {
			e6: -1,
			loading: false,
			search: "",
			headers: [],

			items: [],

			item_aux:
			{
				user: {
					name: "",
					last_name: "",
					email: "",
					cui_passport: "",
				},
				no_passengers: 0,
				pick_up_time: "",
				pick_up_date: "",
				reservation_number: 0,
				reservation_status_id: 0,
				reservation_detail: [
					{
						fee: {
							measure_type: {
								abbreviation: "",
							},
						}
					}
				],

				origin: {
					place: ""
				},
				destination: {
					place: ""
				},
				driver: {
					user: {
						name: "",
						last_name: "",
					},
					image: ""
				},
			},
			dialog_edit: false,
			dialog_detalles: false,
			dialog_aprobar: false,
			dialog_start: false,
			dialog_reclamo: false,
			comment: "",
			isEdit: false,
			isDelete: false,
			show1: false,
			valid_claim_form: false,
			client_id: 0,
			roles: [
				{ num: 2, name: 'Administrador' },
				{ num: 3, name: 'Conductor' },
				{ num: 4, name: 'Cliente' },
				{ num: 5, name: 'Colaborador' },
			],
			claim_types: [],
			valid: true,
			vehicles: [],
			drivers: [],
			fees: [],
			ifArchive: false,
			rating: 0,
			comment: "",
			claim_type: 0,
			exchanges: [
				{
					amount: 0,
				}
			],
		};
	},
	async created() {
		let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
		if (informacion_incriptada != null) {
			let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
			//Casteo de información a estring
			let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
			//Casteo de JSON String a Object json
			let r = JSON.parse(informacion_desincriptada)
			// decodifica el token
			let token = r.token;
			let base64Url = token.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			console.log(jsonPayload);
			let usuario = JSON.parse(jsonPayload);
			this.client_id = usuario.id;

			let p1 = this.get_all_reservation(true);
			let p2 = this.get_claim_types();
			await Promise.all([p1, p2]);
			console.log("usuario", r);
		}


	},

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},

		dialog_start(val) {
			val || this.close_dialog_edit();
		}

	},

	methods: {
		selectFee(id) {
			console.log("dsdad");
			console.log(id);
			// busca el id en los fees
			let fee = this.fees.find(fee => fee.id == id);
			console.log(fee);
			// asigna el fee a la reserva
			this.item_aux.reservation_detail[0].price = fee.total;
			this.item_aux.reservation_detail[0].fee.distance = fee.distance;
			this.item_aux.reservation_detail[0].fee.measure_type = fee.measure_type;
			this.item_aux.reservation_detail[0].destination.id = fee.destination_id;
			this.item_aux.reservation_detail[0].origin.id = fee.origin_id;
		},

		async getUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getUser();
				// this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los usuarios", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_claim_types() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_claim_types();
				this.claim_types = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos de reclamos", { timeout: 2000 });
				this.loading = false;
			}
		},


		async deleteVehicle(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteVehicle(item.id);
				this.$toast.success("Usuario eliminado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar al usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createUser(this.item_aux);
				this.$toast.success("Usuario creado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateReservationDetail() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.update_reservation_status(this.item_aux);
				this.$toast.success("La reservación ha iniciado!", { timeout: 2000 });
				this.get_all_reservation();
				this.loading = false;
				this.close_dialog_edit();
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar al usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		exportarExcel() {
			let data = XLSX.utils.json_to_sheet(this.items)
			const workbook = XLSX.utils.book_new()
			const filename = 'Tipos_vehiculos'
			XLSX.utils.book_append_sheet(workbook, data, filename)
			XLSX.writeFile(workbook, `${filename}.xlsx`)
		},

		async modificar_item(item) {
			// crea una promesa para obtener las marcas y los tipos
			let p1 = this.get_drivers_available();
			let p2 = this.get_vehicle_available();
			let p3 = this.getFee();
			let p4 = this.getExchangeRate();
			await Promise.all([p1, p2, p3, p4]);
			this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;

		},

		aprobar_reserva(item) {
			this.item_aux = Object.assign({}, item);
			this.dialog_aprobar = true;
		},

		crear_item() {
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},

		close_dialog_edit() {
			try {

				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.comment = "";
				this.rating = 0;

			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if (this.isEdit) {
				console.log("editando", this.item_aux);
				this.updateReservationDetail();
				this.close_dialog_edit();
			} else {
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createUser();
				this.close_dialog_edit();
			}
		},

		delete_item(item) {
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete() {
			this.deleteVehicle(this.item_aux);
			this.close_dialog_edit();
		},

		async ver_detalle(item) {
			await this.get_info_reservation(item.id);
			// this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);
			this.dialog_detalles = true;
		},

		async get_all_reservation(calification) {
			console.log(calification);
			this.ifArchive = calification;
			if (calification == true) {
				this.headers = [
					{
						text: "# de Reserva",
						align: "center",
						sortable: true,
						value: "reservation_number",
					},
					{
						text: "Estado de Reserva",
						align: "center",
						sortable: true,
						value: "reservation_status",
					},
					{
						text: "Origen",
						align: "end",
						sortable: true,
						value: "reservation_detail[0].origin",
					},
					{
						text: "Destino",
						align: "start",
						sortable: true,
						value: "reservation_detail[0].destination",
					},
					{
						text: "Conductor",
						align: "center",
						sortable: true,
						value: "reservation_detail[0].driver",
					},
					{
						text: "Vehiculo",
						align: "center",
						sortable: true,
						value: "reservation_detail[0].vehicle",
					},
					{
						text: "Acciones",
						align: "center",
						sortable: false,
						value: "actions",
					},
				];

			} else {
				this.headers = [
					{
						text: "# de Reserva",
						align: "center",
						sortable: true,
						value: "reservation_number",
					},
					{
						text: "Estado de Reserva",
						align: "center",
						sortable: true,
						value: "reservation_status",
					},
					{
						text: "Origen",
						align: "end",
						sortable: true,
						value: "reservation_detail[0].origin",
					},
					{
						text: "Destino",
						align: "start",
						sortable: true,
						value: "reservation_detail[0].destination",
					},
					{
						text: "Conductor",
						align: "center",
						sortable: true,
						value: "reservation_detail[0].driver",
					},
					{
						text: "Vehiculo",
						align: "center",
						sortable: true,
						value: "reservation_detail[0].vehicle",
					},
					{
						text: "Calificacion",
						align: "center",
						sortable: true,
						value: "quality_service",


					},
					{
						text: "Acciones",
						align: "center",
						sortable: false,
						value: "actions",
					},
				];
			}


			try {
				let r = await this.$store.state.services.administradorService.get_info_reservation_client(this.client_id, calification);
				if (r.status == 200) {



					r.data.filter((item) => {
						item.complete_name = item.user.name + " " + item.user.last_name;
					});

					this.items = r.data;
				} else {
					this.$toast.error("Error al obtener las reservas", { timeout: 2000 });
				}

			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las reservas", { timeout: 2000 });
			}
		},

		async get_all_reservation_claim() {
			try {
				let r = await this.$store.state.services.administradorService.get_all_reservation_claim(this.client_id);
				if (r.status == 200) {


					this.headers = [
						{
							text: "# de Reserva",
							align: "center",
							sortable: true,
							value: "reservation_number",
						},
						{
							text: "Claim Description",
							align: "center",
							sortable: true,
							value: "claim[0].claim_type.description",
						},
						{
							text: "Origen",
							align: "end",
							sortable: true,
							value: "reservation_detail[0].origin",
						},
						{
							text: "Destino",
							align: "start",
							sortable: true,
							value: "reservation_detail[0].destination",
						},
						{
							text: "Conductor",
							align: "center",
							sortable: true,
							value: "reservation_detail[0].driver",
						},
						{
							text: "Vehiculo",
							align: "center",
							sortable: true,
							value: "reservation_detail[0].vehicle",
						},

					];

					r.data.filter((item) => {
						item.complete_name = item.user.name + " " + item.user.last_name;
					});

					this.items = r.data;
				}
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las reservas", { timeout: 2000 });
			}
		},


		async get_info_reservation(id) {
			try {

				let r = await this.$store.state.services.administradorService.get_info_reservation(id);

				if (r.status == 200) {
					console.log(r.data);

					if (r.data.driver == null) {
						r.data.driver = {
							user: {
								name: "",
								last_name: "",
							},
							image: ""
						}
					}
					// this.e6 = 1;
					console.log(r.data);
					// this.item_aux = r.data;

					this.item_aux = Object.assign({}, r.data);
					this.e6 = this.item_aux.reservation.reservation_status_id;
					this.loading = false;
					this.e6 = this.item_aux.reservation.reservation_status_id;
				} else {
					this.$toast.error("Error al obtener la informacion de la reservacion")
					this.loading = false;
				}

			} catch (error) {

				this.$toast.error("Error al obtener la informacion de la reservacion")
				this.loading = false;
				console.log(error);


			}

		},

		async get_drivers_available() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_drivers_available();
				this.drivers = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los conductores", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_vehicle_available() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_vehicle_available();
				this.vehicles = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los vehiculos", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_reservation() {
			try {
				this.loading = true;
				this.item_aux.reservation_status_id = 2;
				let r = await this.$store.state.services.administradorService.update_reservation(this.item_aux);
				this.$toast.success("Reserva actualizada", { timeout: 2000 });
				this.loading = false;
				this.dialog_aprobar = false;
				this.get_all_reservation(1);
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar la reserva", { timeout: 2000 });
				this.loading = false;
			}
		},

		async getFee() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getFee();
				this.fees = r.data;
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las tarifas", { timeout: 2000 });
				this.loading = false;
			}
		},

		async getExchangeRate() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getExchangeRate();
				this.exchanges = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos de cambio", { timeout: 2000 });
				this.loading = false;
			}
		},

		calificar(item) {
			this.item_aux = Object.assign({}, item);
			this.dialog_start = true;
		},

		async calificar_acept() {
			try {
				this.loading = true;
				let body = {
					reservation_id: this.item_aux.id,
					starts: this.rating,
					comment: this.comment
				}
				console.log(body);
				let r = await this.$store.state.services.administradorService.create_qualification(body);
				this.$toast.success("Reserva calificada", { timeout: 2000 });
				this.loading = false;
				this.dialog_start = false;
				this.get_all_reservation(true);
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al calificar la reserva", { timeout: 2000 });
				this.loading = false;
			}
		},

		reclamar(item) {
			this.item_aux = Object.assign({}, item);
			this.dialog_reclamo = true;
		},

		async reclamar_acept() {
			try {
				this.loading = true;
				let body = {
					reservation_id: this.item_aux.id,
					claim_type_id: this.claim_type
				}
				console.log(body);
				let r = await this.$store.state.services.administradorService.create_claim(body);
				console.log(r);

				if (r.status == 201) {
					this.$toast.success("Claim send !", { timeout: 2000 });
					this.loading = false;
					this.dialog_reclamo = false;
					this.claim_type = -1;
					this.get_all_reservation(this.ifArchive);
				} else {
					this.$toast.error("Error al realizar el reclamo", { timeout: 2000 });
					this.loading = false;
				}


			} catch (error) {
				console.log(error);
				this.$toast.error("Error al realizar el reclamo", { timeout: 2000 });
				this.loading = false;
			}

		},

	},

};