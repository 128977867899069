import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Descripcion",
          align: "center",
          sortable: true,
          value: "description",
        },
				{
          text: "Abreviatura",
          align: "center",
          sortable: true,
          value: "abbreviation",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

			items: [],

			item_aux: {
				id: -1,
				decription: '',
				abbreviation: '',
			},
			dialog_edit: false,
			isEdit: false,
			isDelete: false,
			valid: true,
			currencies: [],
    };
  },
  async created() {
		// crea una promesa para obtener los datos de los tipos de vehiculos
		let r1 =await this.getMeasure_type();	

		// crea la promesa para obtener los datos de los tipos de vehiculos
		await Promise.all([r1]);

  },

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},
	
  methods: {


		async getMeasure_type() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getMeasure_type();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos de medida", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async deleteMeasure_type(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteMeasure_type(item.id);
				this.$toast.success("Tipo de medida eliminado", { timeout: 2000 });
				this.getMeasure_type();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar el tipo de medida", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createMeasure_type() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createMeasure_type(this.item_aux);
				this.$toast.success("Marca creada", { timeout: 2000 });
				this.getMeasure_type();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el tipo de medida", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateMeasure_type() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateMeasure_type(this.item_aux);
				this.$toast.success("Tipo de medida actualizado", { timeout: 2000 });
				this.getMeasure_type();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el tipo de medida", { timeout: 2000 });
				this.loading = false;
			}
		},

    exportarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items)
      const workbook = XLSX.utils.book_new()
      const filename = 'Tipos_vehiculos'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

		modificar_item(item){
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;
			
		},

		crear_item(){
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},
		
		close_dialog_edit() {
			try {
				if(!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if(this.isEdit){
				console.log("editando", this.item_aux);
				this.updateMeasure_type();
				this.close_dialog_edit();
			}else{
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createMeasure_type();
				this.close_dialog_edit();
			}
		},

		delete_item(item){
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete(){
			this.deleteMeasure_type(this.item_aux);
			this.close_dialog_edit();
		}



   


  },
};