import barnerLogo from "../../assets/QuetzalShuttle_Logo_FndTransparente.png";

export default {


  name: "Register",
  data() {
    return {
      barnerLogo,
      dialogDocs: false,
      src: null,
      titulo: '',
      loading: false,
      departamentos: [],
      municipios: [],
      isFormValid: true,
      register: {
        cui_passport: "",
        confirmar_correo: "",
        name: "",
        last_name: "",
        email: "",
        confirmPassword: "",
        password: "",
        isNew: true,
      },

      showPass:false,
      showPassC: false,

      password_nueva: {
        a: (v) => !!v || "Campo obligatorio",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => /[0-9]+/.test(v) || 'Debe contener al menos un número',
        d: (v) => /[!-/]+|@+/.test(v) || 'Debe contener al menos un 1 caracter especial',
      },

      confirmarRules: {
        a: (v) => !!v || "Campo obligatorio",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => (v === this.register.password) || 'Las conttraseñas no coinciden'
      },

      rules_campo_obligatorio: [
        (v) => !!v || "Campo obligatorio"
      ],
      
      emailRules: [
        v => !!v || 'Campo obligatorio',
        v => /.+@.+/.test(v) || 'El correo no es válido',
      ],

      emailRulesC: [
        v => !!v || 'Campo obligatorio',
        v => /.+@.+/.test(v) || 'El correo no es válido',
        v => (v === this.register.email) || 'Los correos no coinciden'
      ],

      show:false,


    };
  },



  watch: {
    "register.contratos"() {
      if (this.register.contratos != true) {
        this.register.contratos = null;
      }
    }
  },

  async created() {
    await this.getDeps();

  },
  methods: {

    documentos(item) {
      this.titulo = item === 1 ? "TÉRMINOS Y CONDICIONES DECLARA-TIC" : "COBROS AUTOMATICOS"
      this.src = item === 1 ? '/docs/Terminos y condiciones Declara-TIC.pdf' : '/docs/COBROS AUTOMATICOS.pdf'
      this.dialogDocs = true;
      //console.log('docuemtnos ' + item);
    },

    verMuni() {
      if (this.register.departamento !== null) {
        // let dep = this.departamentos.filter(x => x.id === this.register.departamento)[0];
        this.municipios = this.departamentos.filter(x => x.id === this.register.departamento.id)[0].municipio;
        // console.log('this.municipio');
        // console.log(this.municipios);
        return true;
      }
    },

    async getDeps() {
      try {
        let r = await this.$store.state.services.registerService.departamentos();
        //console.log('r.data DEPTARTAMENTOS');
        this.departamentos = r.data.data;
      } catch (error) {
        this.$toastr.error("Ocurrió un error al obtener los departamentos");
      }
    },

    clear() {
      this.register = {
        cui_passport: "",
        name: "",
        last_name: "",
        email: "",
        confirmPassword: "",
        password: "",
        isNew: true,
      }
    },
    async registrar() {
      //console.log(this.v$.register.$touch());
   
      if (this.$refs.formRegistro.validate()) {
        this.loading = true;
        console.log("usuario registrado");
        console.log(this.register);
        try {
          let r = await this.$store.state.services.administradorService.createUser(this.register)
          console.log(r);
          //console.log(r.data.success);
          //console.log(r.data);
          if (r.data.data === false) {
            this.$toast.error(r.data.message, { timeout: 3000, position: "top-center" })
            this.loading = false;
          } else {
            this.$toast.success("Usuario creado exitosamente, revisa tu correo para seguir con el proceso de suscripción", { timeout: 8000, position: "top-center" })
            this.$router.push("/")
          }
        } catch (error) {
          //console.log(error);
          this.$toast.error("Error al crear el usuario, comunicate con soporte", { timeout: 3000, position: "top-center" })
          this.loading = false;
        }
      }
    },
    inicio() {
      let home = sessionStorage.getItem("home");
      this.$router.push(home);
    }
  }
};