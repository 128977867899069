import Axios from 'axios'
import router from '../router'

import loginService from "../services/login/loginService.js";
import registerService from "../services/register/registerService.js";
import administradorService from "../services/administrador/administradorService.js";
import usuarioService from '../services/usuario/usuarioService';
import presentacionService from "../services/presentacion/presentacionService.js";

//INCRIPTACION
// import CryptoJS from "crypto-js";
import reportesService from '../services/reportes/reportesService.js';

// URL.env
let baseUrl = process.env.VUE_APP_SERVICE_URL1
let baseUrl2 = process.env.VUE_APP_SERVICE_URL2

/*
*Toast notifaciones
*/
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";



//console.log("RUTA 2");
// console.log(baseUrl2);


// // Axios Configuration
// // Desincriptacion de datos del usuario (valor, clave)
// let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
// if (informacion_incriptada != null) {
//   let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
//   let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);

//   //Casteo de JSON String a Object json
//   let r = JSON.parse(informacion_desincriptada)
//   //console.log("r services")
//   //console.log(r.token);
//   Axios.defaults.headers.common.Accept = 'application/json'
//   if (r.token !== null) {
//     Axios.defaults.headers.common.Authorization = `Bearer ${r.token}`
//   }
// }

// Axios Configuration api-key
Axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY


Axios.interceptors.response.use(response => {
  return response
}, error => {
  if (!error.response) {
    router.push('/network_error')
    return
  } if (error.response.status === 404) {
    // router.push('/404')
    // toastr.warning(error.response.data.error, "Advertencia");
    this.$toast.error("Error de red, intente nuevamente")
    //console.log('usuario y contraseña incorrecta');
    return;
  } else if (error.response.status === 401) {
    router.push('/401')
    sessionStorage.removeItem(process.env.VUE_APP_NAME_SESSION);
    this.$router.push('/login')
  }else if (error.response.status === 500) {
    console.log(error.response);
    alert(error.response.data.message)

    // router.push('/500')
    // toastr.error(error.response.data.error, 'Advertencia')
    // this.$toast.error(error.response.message, 'Advertencia');
    throw error;
  } 
  
  else {
    if (typeof error.response.data.error === 'object') {
      // for (let value of Object.values(error.response.data.error)) {
      // toastr.error(value, 'Advertencia')
      // }
    } else if (typeof error.response.data.error !== 'object' && (error.response.status === 422 || error.response.status === 423)) {
      router.push('/423')
      // toastr.info(error.response.data.error, "Advertencia");
      return;
    } else if (error.response.status === 403) {
      // router.push('/403')
      this.$toast.error("Usuario y/o Contraseña incorrectos")

      // toastr.error(error.response.data.error, 'Advertencia')
      // router.push('/panel')
    }
  }
  return error
});


export default {
  loginService: new loginService(Axios, baseUrl),
  registerService: new registerService(Axios, baseUrl),
  administradorService: new administradorService(Axios, baseUrl),
  usuarioService: new usuarioService(Axios, baseUrl),
  presentacionService: new presentacionService(Axios, baseUrl),


  reportesService: new reportesService(Axios, baseUrl2)
}
