import { render, staticRenderFns } from "./principal.vue?vue&type=template&id=7f044a14&scoped=true&"
import script from "./principal.js?vue&type=script&lang=js&"
export * from "./principal.js?vue&type=script&lang=js&"
import style0 from "./principal.css?vue&type=style&index=0&id=7f044a14&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f044a14",
  null
  
)

export default component.exports