import visa from '@/assets/visa.png'

export default {

  props: ['tarjeta', 'info_suscripcion'],
  data() {
    return {
      visa: visa,
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      card: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        checkbox: false,
      },
      valid: true,
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,

      nombreRules: [
        v => !!v || 'El Nombre es requerido',
      ],

      numeroRules: [
        v => !!v || 'El Número de a tarjeta es requerido',
      ],

      mesesRules: [
        v => !!v || 'El Mes es requerido',
      ],

      aniosRules: [
        v => !!v || 'El Año es requerido',
      ],

      cvvRules: [
        v => !!v || 'El CVV es requerido',
      ],

      meses: [
        { id: "01", nombre: "Enero" },
        { id: "02", nombre: "Febrero" },
        { id: "03", nombre: "Marzo" },
        { id: "04", nombre: "Abril" },
        { id: "05", nombre: "Mayo" },
        { id: "06", nombre: "Junio" },
        { id: "07", nombre: "Julio" },
        { id: "08", nombre: "Agosto" },
        { id: "09", nombre: "Septiembre" },
        { id: "10", nombre: "Octubre" },
        { id: "11", nombre: "Noviembre" },
        { id: "12", nombre: "Diciembre" },
      ],
      anios: [],


      dialogDocs: false,
      src: null,
      titulo: "",


    };
  },
  created() {
    console.log("info de la suscripcion");
    console.log(this.info_suscripcion);



    let anio = new Date(Date.now()).getFullYear();
    this.anios.push(anio);
    for (let i = 0; i < 12; i++) {
      this.anios.push(anio + (i + 1));
    }

  },
  mounted() {
    console.log("info de la suscripcion");
    console.log(this.info_suscripcion);
    this.cardNumberTemp = this.otherCardMask;
    // document.getElementById("cardNumber").focus();


    let tarjeta = JSON.parse(atob(sessionStorage.getItem("CARD_INFO")));
    console.log("info de la tarjeta");
    console.log(tarjeta);
    this.card.cardName = tarjeta.propiertario;
    this.card.cardNumber = tarjeta.numero;
    this.card.cardMonth = tarjeta.mes;
    this.card.cardYear = parseInt(tarjeta.anio);

  },
  computed: {
    getCardType() {
      let number = this.card.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
    minCardMonth() {
      if (this.card.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },

    size() {
      const size = { sm: 'small', md: 'medium', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    },
  },
  watch: {
    cardYear() {
      if (this.card.cardMonth < this.minCardMonth) {
        this.card.cardMonth = "";
      }
    }
  },
  methods: {

    convertirnumero(numero) {


      let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
      return dollarUSLocale.format(parseFloat(numero).toFixed(2))

    },

    check() {
      if (this.card.checkbox === true) {
        this.card.checkbox = true;
      } else {
        this.card.checkbox = false;
      }
    },

    documentos(item) {
      this.card.checkbox = false;
      this.titulo = item === 1 ? "Términos y condiciones Declara-TIC" : "Términos y condiciones Declara-TIC"
      this.src = item === 1 ? '/docs/TERMINOS DE PRIVACIDAD.pdf' : '/docs/COBROS AUTOMATICOS.pdf'
      this.dialogDocs = true;
      //console.log('docuemtnos ' + item);
    },

    enviarTarjeta() {
      if (this.$refs.card.validate()) {
        this.$emit('tarjeta', this.card);
        this.card.cardCvv = "";
        this.card.checkbox = null;


      } else {
        this.$refs.card.validate();
      }
    },

    atras() {
      this.$emit('atras');
    },

    nnnn(n) {
      return n < 10 ? "0" + n : n
    },

    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    }
  }
}