var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.hasVerticalScroll),expression:"hasVerticalScroll"}],attrs:{"id":"inspire"}},[(_vm.mostrarLateral)?_c('v-navigation-drawer',{attrs:{"app":"","mini-variant":_vm.mini,"width":"350px"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',{staticClass:"animated fadeInLeft zoomMenu",on:{"click":function($event){return _vm.selectView('/cerrarSesion')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('logout')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Cerrar Sesión')}})],1)],1)]},proxy:true}],null,false,3685616858),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',{on:{"click":function($event){return _vm.$router.go()}}},[_c('v-img',{attrs:{"src":_vm.logo_ext}})],1),_c('v-list-item',[(_vm.mostrarLateral)?_c('div',[_c('v-card-text',[_vm._v(_vm._s(_vm.nombre_usuario))])],1):_vm._e()])],1),_vm._l((_vm.items_menu_usuario),function(item,key){return _c('v-list',{key:key},[(item.sub.length == 0)?_c('v-list-item',{on:{"click":function($event){(_vm.show = false,
          _vm.selectView(item.route))}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_c('v-list-group',{attrs:{"value":false,"prepend-icon":item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.sub),function(sub_item,key){return _c('v-list',{key:'S' + key,attrs:{"link":""}},[(sub_item.sub.length == 0)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){(_vm.drawer = false
            , _vm.selectView(sub_item.route))}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(sub_item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(sub_item.title))])],1):_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(sub_item.title))])],1)]},proxy:true}],null,true)},_vm._l((sub_item.sub),function(sub_sub,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){(_vm.drawer = false,
              _vm.selectView(sub_sub.route))}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(sub_sub.title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(sub_sub.icon)}})],1)],1)}),1)],1)}),1)],1)})],2)],1):_vm._e(),_c('v-app-bar',_vm._b({staticClass:"text-white p-0",staticStyle:{"background-color":"#116009"},attrs:{"app":"","elevation":"5"}},'v-app-bar',_vm.size,false),[(!_vm.mostrarLateral)?_c('v-img',{staticStyle:{"magin-left":"-1px","cursor":"pointer"},attrs:{"src":_vm.logo_ext,"max-height":"90","max-width":"120","contain":""},on:{"click":function($event){return _vm.$router.go()}}}):_vm._e(),(_vm.mostrarLateral)?_c('v-app-bar-nav-icon',{staticClass:"text-white",on:{"click":function($event){return _vm.vista_menu()}}}):_vm._e(),(!_vm.mostrarLateral)?_c('v-icon',{staticClass:"mx-5 text-white",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go()}}},[_vm._v("other_houses ")]):_vm._e(),(!_vm.mostrarLateral)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-menu',{attrs:{"max-height":"392px","bottom":"","offset-y":"","close-on-click":false,"close-on-content-click":false},on:{"click":function($event){_vm.show = true}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[(_vm.usuario_rol == 2)?_c('v-icon',{attrs:{"large":""}},[_vm._v("admin_panel_settings ")]):(_vm.usuario_rol == 3)?_c('v-icon',{attrs:{"large":""}},[_vm._v("directions_car ")]):(_vm.usuario_rol == 4)?_c('v-icon',{attrs:{"large":""}},[_vm._v("account_circle ")]):_vm._e()],1)]}}],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},_vm._l((_vm.items_menu_usuario),function(item,key){return _c('v-list',{key:key},[(item.sub.length == 0)?_c('v-list-item',{on:{"click":function($event){(_vm.show = false,
                _vm.selectView(item.route))}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_c('v-list-group',{attrs:{"value":false,"prepend-icon":item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.sub),function(sub_item,key){return _c('v-list',{key:'S' + key,attrs:{"link":""}},[(sub_item.sub.length == 0)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){(_vm.show = false,
                  _vm.selectView(sub_item.route))}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(sub_item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(sub_item.title))])],1):_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(sub_item.title))])],1)]},proxy:true}],null,true)},_vm._l((sub_item.sub),function(sub_sub,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){(_vm.show = false,
                    _vm.selectView(sub_sub.route))}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(sub_sub.title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(sub_sub.icon)}})],1)],1)}),1)],1)}),1)],1)}),1)],1)]}}],null,false,2089910688)},[(_vm.usuario_rol == 2 )?_c('span',[_vm._v("Administrador")]):(_vm.usuario_rol == 3 )?_c('span',[_vm._v("Piloto")]):(_vm.usuario_rol == 4 )?_c('span',[_vm._v("User")]):_vm._e()]):_vm._e(),_c('v-divider',{staticStyle:{"color":"#116009"}}),(!_vm.mostrarLateral)?_c('div',[_c('v-card-text',[_vm._v(_vm._s(_vm.nombre_usuario))])],1):_vm._e(),(!_vm.mostrarLateral)?_c('div',[_c('v-avatar',{staticClass:"mr-3",staticStyle:{"cursor":"pointer"},attrs:{"size":"45","color":"white"}},[_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.nombre_usuario.charAt(0) + _vm.nombre_usuario.split(" ")[1].charAt(0)))])])],1):_vm._e(),(!_vm.mostrarLateral)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.selectView('/cerrarSesion')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("logout")])],1)],1)]}}],null,false,2417279232)},[_c('span',[_vm._v("Cerrar Sesión")])]):_vm._e()],1),_c('v-main',{staticStyle:{"background-color":"#f7f7f7"}},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view'),(_vm.hasScroll)?_c('a',{staticClass:"mr-3 animated zoomIn volverArriba",staticStyle:{"text-decoration":"none"},attrs:{"href":"#inspire"}},[_c('v-tooltip',{attrs:{"top":"","color":"#77A100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"#77A100"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_upward")])],1)]}}],null,false,2688159403)},[_c('span',[_vm._v("up")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }