import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
//INCRIPTACION
import CryptoJS from "crypto-js";

import error_401 from "../views/errors/401";
import error_403 from "../views/errors/403";
import error_404 from "../views/errors/404";
import error_423 from "../views/errors/423";
import network_error from "../views/errors/network_error";
import error from "../views/errors/error";
// import ladingpage from "../views/ladingpage/landingpage.vue";
// import home from "../views/home/home.vue";
import register from "../views/register/register.vue";
import login from "../views/login/login.vue";
// import dasboard from "../views/dashboard/dashboard.vue"
import principal from "../views/principal/principal.vue"
import administrador from "../views/administrador/administrador/administrador.vue";
import empleado from "../views/empleado/empleado.vue"
import usuario_final from "../views/usuario_final/principal/usuario_final.vue"
import recuperar_pass from "../views/recuperar_contraseña/recuperar_contraseña/recuperar_contraseña.vue"
import confirmacion_recuperar_pass from "../views/recuperar_contraseña/confirmacion_recuperar_contraseña/confirmacion_recuperar_contraseña.vue"
import perfil_usuario from "../views/usuario_final/perfil_usuario/perfil_usuario.vue"

/**
 * 
 * RUTAS ADMINISTRADOR
 * 
**/
// quetzal_shutlle\src\router\index.js

import brand_vehicle from '../views/administrador/brand_vehicle/brand_vehicle.vue';
import type_vehicle from '../views/administrador/type_vehicle/type_vehicle.vue';
import vehicle from '../views/administrador/vehicle/vehicle.vue';
import exchange_rate from '../views/administrador/exchange_rate/exchange_rate.vue';
import measure_type from '../views/administrador/measure_type/measure_type.vue';
import fee from '../views/administrador/fee/fee.vue';
import currency from '../views/administrador/currency/currency.vue';
import payment_type from '../views/administrador/payment_type/payment_type.vue';
import user from '../views/administrador/user/user.vue';
import reservation from '../views/administrador/reservation/reservation.vue';
import confirmar_correo from "../views/confirmar_correo/confirmar_correo.vue"
import user_reservation from '../views/usuario_final/user_reservation/user_reservation.vue';
import user_reservation_internal from '../views/usuario_final/user_reservation_internal/user_reservation.vue';
import country from '../views/administrador/country/country.vue';
import departments from '../views/administrador/departments/departments.vue';
import municipalities from '../views/administrador/municipalities/municipalities.vue';
import places from '../views/administrador/places/places.vue';
import user_tracking from '../views/usuario_final/user_tracking/user_tracking.vue';
import reservation_driver  from '../views/empleado_interno/reservation/reservation.vue';
import client from '../views/usuario_final/principal/usuario_final.vue';
import reservation_user from '../views/usuario_final/reservation/reservation.vue';
import claim from '../views/administrador/claim/claim.vue';
import account from '../views/administrador/account/account.vue';

Vue.use(VueRouter)

//proteger rutas admin_user
const isAdmin = (to, from, next) => {
  //Desincriptacion de datos del usuario (valor, clave)
  let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
  if (informacion_incriptada != null) {
    let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
    //Casteo de información a estring
    let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
    //Casteo de JSON String a Object json
    let r = JSON.parse(informacion_desincriptada)

    console.log("usuario",r);
    // let user = usuario;
    //claves para interacción con el sessión storage
    return r.role == 2 ? next() : (sessionStorage.removeItem('dsgfv/&#$%sdksdn..sd5454sd-todo'), sessionStorage.removeItem('dsgfv/&#$%sdksdn..sd5454sd-tic'), next('/'));
  }
}

//proteger rutas admin_user
const isColaborador = (to, from, next) => {
  //Desincriptacion de datos del usuario (valor, clave)
  let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
  if (informacion_incriptada != null) {
    let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
    //Casteo de información a estring
    let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
    //Casteo de JSON String a Object json
    let r = JSON.parse(informacion_desincriptada)

    console.log("usuario",r);
    // let user = usuario;
    //claves para interacción con el sessión storage
    return r.role == 3 ? next() : (sessionStorage.removeItem('dsgfv/&#$%sdksdn..sd5454sd-todo'), sessionStorage.removeItem('dsgfv/&#$%sdksdn..sd5454sd-tic'), next('/'));
  }
}

  const routes = [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: home
    // },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/recuperar_pass',
      name: 'recuperar_pass',
      component: recuperar_pass
    },
    {
      path: "/recuperacion_confirmacion/:encriptado",
      name: "confirmacion_recuperacion",
      component: confirmacion_recuperar_pass
    },
    {
      path: "/confirm-email/:token",
      name: "confirmacion_correo",
      component: confirmar_correo
    },
    {
      path: "/reserve-tracking/:token",
      name: "reserve-tracking",
      component: user_tracking
    },
    {
      path: "/reserve-user/:data",
      name: "reserve-user-external",
      component: user_reservation
    },

    {
      path: '/dashboard',
      component: principal,
      children: [
        {
          path: "/brand_vehicle", 
          name: "brand_vehicle",
          component: brand_vehicle,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/type_vehicle", 
          name: "type_vehicle",
          component: type_vehicle,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/vehicle",
          name: "vehicle",
          component: vehicle,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/exchange_rate",
          name: "exchange_rate",
          component: exchange_rate,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/measure_type",
          name: "measure_type",
          component: measure_type,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/fee",
          name: "fee",
          component: fee,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/currency",
          name: "currency",
          component: currency,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/country",
          name: "country",
          component: country,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/department",
          name: "department",
          component: departments,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/claim",
          name: "claim",
          component: claim,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/municipality",
          name: "municipality",
          component: municipalities,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/place",
          name: "place",
          component: places,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/payment_type",
          name: "payment_type",
          component: payment_type,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/user",
          name: "user",
          component: user,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/reservation",
          name: "reservation",
          component: reservation,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/reservation_driver",
          name: "reservation_driver",
          component: reservation_driver,
          beforeEnter: multiguard([isColaborador])
        },
        {
          path: "/reservation_user",
          name: "reservation_user",
          component: reservation_user,
        },
        {
          path: "/administrador",
          name: "administrador",
          component: administrador,
          beforeEnter: multiguard([isAdmin])
        },
        {
          path: "/client",
          name: "client",
          component: client
        },
        {
          path: "/reserve-user",
          name: "reserve-user",
          component: user_reservation_internal
        },
        {
          path: "/empleado",
          name: "empleado",
          component: empleado,
          beforeEnter: multiguard([isColaborador])
        },
        {
          path: "/perfil_usuario/:tab",
          name: "perfil_usuario",
          component: perfil_usuario
        },
        {
          path: "/account",
          name: "account",
          component: account,
          beforeEnter: multiguard([isAdmin])
        }
  
        //demas vista aqui
      ]
    },
    {
      path: '/401',
      name: '401',
      component: error_401
    },
    {
      path: '/403',
      name: '403',
      component: error_403
    },
    {
      path: '/404',
      name: '404',
      component: error_404
    },
    {
      path: '/423',
      name: '423',
      component: error_423
    },
    {
      path: '/error',
      name: 'error',
      component: error
    },
    {
      path: '/network_error',
      name: 'network_error',
      component: network_error
    },
    {
      path: '*',
      redirect: "/error"
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  export default router
