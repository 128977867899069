import logo_quetzal from '../../../assets/Q_Logo_BordeBlanco.png'

export default {

	data() {
		return {
			logo_quetzal,
			id:-1,
			e6: 0,
			loading: false,

			item_aux: 
			{
				reservation: {
						no_passengers: 0,
						pick_up_time: "",
						pick_up_date: "",
						reservation_number: 0,
						reservation_status_id: 0,
						user: {
								name: "",
								last_name: "",
								email: ""
						}
				},
				origin: {
						place: ""
				},
				destination: {
						place: ""
				},
				driver: {
					user: {
						name: "",
						last_name: "",
					},
						image: ""
				},	
		}
			
		};
	},

	async created() {
		this.loading = true;
		setTimeout(() => {
			this.loading = false;
		}, 5000);

		// obten el parametro token
		let {token} = this.$route.params;
		console.log(token);
		this.id = atob(token);
		console.log(this.id);
		await this.get_info_reservation();
	},

	methods: {

		async get_info_reservation() {
			try {
				
				let r = await this.$store.state.services.administradorService.get_info_reservation(this.id);

				if (r.status == 200) {
					console.log(r.data);

					if (r.data.driver == null) {
						r.data.driver = {
							user: {
								name: "",
							last_name: "",
							},
							image: ""
						}
					}
						// this.e6 = 1;
					console.log(r.data);
					this.item_aux = r.data;
					this.loading = false;
					this.e6 = this.item_aux.reservation.reservation_status_id;
				}else
				{
					this.$toast.error("Error al obtener la informacion de la reservacion")
					this.loading = false;
				}

			} catch (error) {

				this.$toast.error("Error al obtener la informacion de la reservacion")
				this.loading = false;
				console.log(error);

				
			}

	},
}

}

