//INCRIPTACION
// import CryptoJS from "crypto-js";
import logo_ext from "../../assets/QuetzalShuttle_Logo.jpg"
import logo_red from "@/assets/logo_circulo.png"
// import Axios from "axios";
import logo_back from "@/assets/fondo_mantenimiento.png";

import Axios from 'axios'


//INCRIPTACION
import CryptoJS from "crypto-js";

export default {
  data: () => ({
    drawer: false,
    group: null,
    notificaciones: [],
    hasScroll: false,

    items_perfil: [
      { text: 'Mi Perfil', icon: 'portrait', accion: "perfil", path: "/perfil_usuario/-1" },
      { text: 'Reporte Anual y Libro de Compras/Ventas', icon: 'event_note', accion: "cerrar", path: "/reporte_mensual_anual_usuario" },
      { text: 'Estado de declaración', icon: 'view_timeline', accion: "cerrar", path: "/estado_declaracion" },
      { text: 'Términos de Privacidad y Servicios', icon: 'privacy_tip', accion: "cerrar", path: "/terminos_privacidad_servicio" },
      { text: 'Compras', icon: 'shopping_cart', accion: "", path: "/seleccion_facturas_compras" },
      { text: 'Cerrar Sesión', icon: 'logout', accion: "cerrar", path: "/cerrarSesion" }
    ],

    items_menu_usuario: [],


    items_perfil_empleado_admin: [
      { text: 'Cerrar Sesión', icon: 'logout', accion: "cerrar", path: "/cerrarSesion" }
    ],


    admins: [
      { title: 'Home', icon: 'mdi-home', route: '/administrador', sub: [] },
      {
        title: 'Mantenimientos', icon: 'admin_panel_settings', path: '',
        sub: [

          {
            title: 'Gestión de usuarios', icon: '', path: '',
            sub: [
              { title: 'Usuarios', icon: 'person', route: '/user', sub: [] },
              // { title: 'Pilotos', icon: 'badge', route: '/vehicle', sub: [] },
              // { title: 'Calificación pilotos', icon: 'hotel_class', route: '/vehicle', sub: [] },
              { title: 'Reclamos', icon: 'bus_alert', route: '/claim', sub: [] },
              // { title: 'Tipos de reclamos', icon: 'report', route: '/vehicle', sub: [] },
            ]
          },
          {
            title: 'Gestión Monetaria', icon: '', route: '/vehicle',
            sub: [
              { title: 'Estado de cuenta', icon: 'account_balance', route: '/account', sub: [] },
              { title: 'Tipos de pago', icon: 'attach_money', route: '/payment_type' },
              { title: 'Pagos', icon: 'payments', route: '/vehicle', },
              { title: 'Promociones', icon: 'sell', route: '/vehicle', },
              { title: 'Monedas', icon: 'paid', route: '/currency', },
              { title: 'Tipo medida', icon: 'balance', route: '/measure_type', },
              { title: 'Tarifario y rutas', icon: 'price_check', route: '/fee' },
              { title: 'Tipo de cambio', icon: 'price_change', route: '/exchange_rate' },
            ]
          },
          {
            title: 'Gestión de vehículos', icon: '', route: '',
            sub: [
              { title: 'Vechículos', icon: 'commute', route: '/vehicle' },
              { title: 'Tipo', icon: 'car_repair', route: '/type_vehicle' },
              { title: 'Marca', icon: 'description', route: '/brand_vehicle' },
            ],
          },

        ]
      },
      {
        title: 'Reservaciones', icon: 'departure_board', route: '/reservation', sub: [] 
      },
      {
        title: 'Ubicaciones', icon: 'map',  path:'', 
        sub: [
          { title: 'Países', icon: 'public', route: '/country', sub: []},
          { title: 'Departamentos', icon: 'location_city', route: '/department' , sub: []},
          { title: 'Municipios', icon: 'location_searching', route: '/municipality', sub: [] },
          { title: 'Lugares', icon: 'place', route: '/place' , sub: []},
        ] 
      },

    ],

    drivers: [
      { title: 'Home', icon: 'mdi-home', route: '/empleado', sub: [] },
      { title: 'Reservaciones', icon: 'departure_board', route: '/reservation_driver', sub: [] },
    ],

    users: [
      { title: 'Home', icon: 'mdi-home', route: '/client', sub: [] },
      { title: 'Reserves', icon: 'style', route: '/reservation_user', sub: [] },
      // asistencia
      { title: 'Do Reserve', icon: 'hail', route: `/reserve-user`, sub: [] },
    ],

    show: false,
    padless: false,
    variant: "default",
    mini: false,
    logo_red,
    logo_ext,
    messages: 0,
    selected: [2],
    benched: 0,
    pantalla: '',
    mostrarLateral: false,
    usuario_rol: 0,
    IDLE_TIMEOUT: 3600,//TIEMPO DE SESSIÓN
    idleSecondsTimer: 0,
    idleSecondsCounter: 0,
    logo_back,

    completo: false,

    nombre_usuario: "",

    roles: [],
    suscripcion_actual: "",
    personal_info: {},
  }),

  async created() {
    sessionStorage.removeItem("CARD_REQUEST");
    this.size;

    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeypress = () => {
      this.idleSecondsCounter = 0;
    };
    this.idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
    let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
    if (informacion_incriptada != null) {
      let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
      //Casteo de información a estring
      let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
      console.log(informacion_desincriptada, "informacion_desincriptada");

      //Casteo de JSON String a Object json
      let r = JSON.parse(informacion_desincriptada)
      this.usuario_rol = r.role;
      Axios.defaults.headers.common.Accept = 'application/json'
      if (r.token !== null) {
        Axios.defaults.headers.common.Authorization = `Bearer ${r.token}`
        let token = r.token;
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        console.log(jsonPayload);
        this.personal_info = jsonPayload;
        this.nombre_usuario = JSON.parse(jsonPayload).name;

      }
    } else {
      this.cerrarSesion();
      this.$toast.warning("Vaya al parecer no has iniciado sesión", { timeout: 2000 })
    }

    this.filtradoItems();
  },

  computed: {
    size() {
      const size = { sm: 'small', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name];
      this.pantalla = size;
      return size ? { [size]: true } : {}
    }
  },
  watch: {

    pantalla() {
      // //console.log("watcher pantalla", this.pantalla);
      if (this.pantalla == undefined) {
        this.mostrarLateral = true;
        // this.items_perfil = this.items_perfil.filter(x => x.icon != 'logout');
      } else {
        this.mostrarLateral = false;
        // this.items_perfil.push();
      }
    }
  },

  methods: {

    hasVerticalScroll(e) {
      this.hasScroll = e.target.defaultView.pageYOffset > 30
    },


    filtradoItems() {
      if (this.usuario_rol == 2) {
        this.items_menu_usuario = this.admins;
      } else if (this.usuario_rol == 3) {
        this.items_menu_usuario = this.drivers;
      } else if (this.usuario_rol == 4) {
        this.items_menu_usuario = this.users;
      }
      else {
        return this.items_perfil;
      }
    },

    CheckIdleTime() {//valida tiempo de sesión
      let pathname = window.location.pathname;
      // console.log(pathname);
      if (pathname == '/') {
        window.clearInterval(this.idleSecondsTimer);
        sessionStorage.removeItem(process.env.VUE_APP_NAME_SESSION);
      } else {
        this.idleSecondsCounter++;
        // console.log(this.idleSecondsCounter);
        if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
          window.clearInterval(this.idleSecondsTimer);
          this.$toast.info("Su sesión ha expirado", { position: "top-center" })
          sessionStorage.removeItem(process.env.VUE_APP_NAME_SESSION);
        }
      }
    },

    vista_menu() {//permite la funcionalidad de minimizar menu lateral
      if (this.drawer) {
        if (this.mini) {
          this.mini = false;
        } else {
          this.mini = true;
        }
      } else {
        this.drawer = true;
      }
    },

    selectView(path) {//seleciona items del menu (Perfil)
      console.log(path);
      try {
        if (path != "/cerrarSesion") {
          if (this.$route.path === path) {
            //console.log("");//evalua si hacemos click sobre un boton ya seleccionado
            this.$router.go();
          } else {
            this.$router.push({ path: path }).catch(error => {
              if (
                error.name !== 'NavigationDuplicated' &&
                !error.message.includes('Avoided redundant navigation to current location')
              ) {
                this.$router.go();
              } else {
                this.$router.go();
              }
            })
          }
        } else {
          this.cerrarSesion();
        }
      } catch (error) {
        //console.log("")
      }
    },

    cerrarSesion() {//Cerrar sesión
      const clave_token = "dsgfv/&#$%sdksdn..sd5454sd-todo"
      const clave_data = "dsgfv/&#$%sdksdn..sd5454sd-tic"
      sessionStorage.removeItem(clave_token)
      sessionStorage.removeItem(clave_data)
      let url = "/"
      this.$router.push({ path: url });
    },


  },
};