import { render, staticRenderFns } from "./measure_type.vue?vue&type=template&id=73b36a28&"
import script from "./measure_type.js?vue&type=script&lang=js&"
export * from "./measure_type.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports