import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import logo_qt from "../../../assets/Q_Logo_BordeBlanco.png"
// import Axios from "axios";
//INCRIPTACION
// import CryptoJS from "crypto-js";

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  name: "Login",
  data() {
    return {
      logo_qt,
      loading: false,
      recovery: {
        email: "",
      }
    };
  },
  validations() {
    return {
      recovery: {
        email: {
          required, min: minLength(1), max: maxLength(200)
        }
      }
    }
  },
  methods: {
    async auth() {
      this.loading = true;
      try {

        let r = await this.$store.state.services.administradorService.userRecovery(this.recovery);
        console.log("Login",r);
        if (r.status == 200) {
          this.$toast.success("We have sent you an email to reset your password")
          let data = r.data.data
          console.log(data);
          this.loading = false;
          this.$router.push("/")
          this.recovery = {};
    
        } 
      } catch (error) {
        this.loading = false;
        this.$toast.info("Please check your email")
      }

    }
  },
};