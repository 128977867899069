import check_img from "../../assets/Detective-check-footprint.svg";

export default {

  data() {
    return {
      check_img,
      loading: false,
      confirmar: {
        token: "",
      }
    };
  },

  async created() {
    await this.verificar_contrasena();
  },

  methods:
  {
    async verificar_contrasena() {

      this.confirmar.token = this.$route.params.token;

      this.loading = true;
      // console.log(this.$route.params.encriptado);
      try {
        let r = await this.$store.state.services.administradorService.verifiedEmail(this.confirmar);
        console.log(r);

        if (r.status == 200) {
          await this.$toast.info(r.data.message, { timeout: 5000 })
          this.loading = false;
          // creaa un timeout para redireccionar a la pagina de login
          setTimeout(() => {
            this.$router.push("/")
          }, 5000);

        } else {
          this.$toast.error("Error en la verificacion de correo electronico")
          this.loading = false;
        }
      } catch (error) {
        this.$toast.error("Error en la verificacion de correo electronico")
        this.loading = false;
        console.log(error);
      }

    }
  },
}