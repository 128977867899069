class presentacionService {//servicios para login
  axios
  baseUrl
  baseUrl2
  baseUrl3

  constructor(axios, baseUrl) {
    this.axios = axios;
    this.baseUrl = `${baseUrl}`;
    this.baseUrl2 = `${baseUrl}/factura/presentacion`;
    this.baseUrl3 = `${baseUrl}/retencion/presentacion`;
  }

  presentarFacturas(body) {
    return this.axios.post(`${this.baseUrl}/presentacion/insert`, body)
  }

  pagoFacturas(presentacion_id) {
    return this.axios.post(`${this.baseUrl}/presentacion/pago`, { presentacion_id: presentacion_id })
  }

  correoFinalizacionPresentacion(body) {
    return this.axios.post(`${this.baseUrl}/presentacion/final/notificacion`, body)
  }

  presentarListado(fecha) {
    return this.axios.post(`${this.baseUrl}/declaracion/formulario`, fecha)
  }

  listadoPendientesPago(fecha) {
    return this.axios.post(`${this.baseUrl}/declaracion/pendiente_pago`, fecha)
  }




  getPresentarFacturas(nit) {
    return this.axios.get(`${this.baseUrl2}/${nit}`)
  }

  getPresentarRetenciones(nit) {
    return this.axios.get(`${this.baseUrl3}/${nit}`)
  }

  getListadoDeclaracionesInicial(body) {
    let url = `${this.baseUrl}/usuario/listado/inicial`
    return this.axios.post(url.toString(), body)
  }

  getListaodDeclaracionesIngreso(body) {
    let url = `${this.baseUrl}/usuario/listado/ingreso`
    return this.axios.post(url.toString(), body)
  }

  forzarEnvio(body) {
    let url = `${this.baseUrl}/declaracion/retencion/no_aplica`
    return this.axios.put(url.toString(), body);
  }

  forzarEnvioA0(body) {
    let url = `${this.baseUrl}/declaracion/no_aplica`
    return this.axios.post(url.toString(), body);
  }

  postlibrosVentas(body) {
    let url = `${this.baseUrl}/libro/ventas`;
    return this.axios.post(url.toString(), body)
  }

  postlibrosCompras(body) {
    let url = `${this.baseUrl}/libro/compras`;
    return this.axios.post(url.toString(), body)
  }
  
  postCierreAnual(body) {
    let url = `${this.baseUrl}/cierre/anual`;
    return this.axios.post(url.toString(), body)
  }

  postSeguimiento(body) {
    let url = `${this.baseUrl}/declaracion/seguimiento`;
    return this.axios.post(url.toString(), body)
  }

  deleteSeguimiento(body) {
    let url = `${this.baseUrl}/declaracion/eliminar`;
    return this.axios.delete(url.toString(), { data: body })
  }

  enviarCorreoNotificacion(body){
    let url= `${this.baseUrl}/notificacion/formulario`;
    return this.axios.post(url.toString(), body);
  }
  
  getClientes(){
    return  this.axios.get(`${this.baseUrl}/usuario/listado/cliente`)
  }

  getSeguimiento(usuario){
    return  this.axios.get(`${this.baseUrl}/declaracion/historico/${usuario}`) 
  }

  validarRetenedores(body){
    let url= `${this.baseUrl}/agentes/validar`;
    return this.axios.post(url.toString(), body);
  }

  validarFacturasSinCategoria(body){
    let url= `${this.baseUrl}/factura/val/tipo_emisor`;
    return this.axios.post(url.toString(), body);
  }

  seleccionFacturasMedianteColaborador(body){
    let url= `${this.baseUrl}/ros/update/facturas_empleado`;
    return this.axios.put(url.toString(), body);
  }

  notificacion_para_seleccion_facturas(body){
    let url = `${this.baseUrl}/ros/notificar/facturas_recibidas`;
    return this.axios.post(url.toString(), body);
  }
}

export default presentacionService;